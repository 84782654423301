function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
import { QuickAddSpotDialogController } from './dialogs/quickAddSpotDialog-controller';
import { metadataDialog } from './dialogs/metadataDialog-controller';
import { AuthConstant } from '../../constants/auth.constant'; //// CONTROLLER ////

(function () {
  'use strict';

  angular.module('comcast.orders').controller('orderGroupDialogController', ['$q', '$scope', '$mdDialog', '$timeout', '$window', 'EndPointService', 'EnumService', 'AssetResourceFactory', 'SpotResourceFactory', 'DestinationResourceFactory', 'OrderResourceFactory', 'NotificationService', 'orderType', 'group', 'allGroups', 'selectedTabIndex', 'AdvertiserResourceFactory', 'AgencyResourceFactory', 'BrandsResourceFactory', 'DestinationTypeResourceFactory', 'DestinationListResourceFactory', 'MarketResourceFactory', 'loginService', '$filter', 'previousSpots', 'previousDests', function ($q, $scope, $mdDialog, $timeout, $window, EndPointService, EnumService, AssetResourceFactory, SpotResourceFactory, DestinationResourceFactory, OrderResourceFactory, NotificationService, orderType, group, allGroups, selectedTabIndex, AdvertiserResourceFactory, AgencyResourceFactory, BrandsResourceFactory, DestinationTypeResourceFactory, DestinationListResourceFactory, MarketResourceFactory, loginService, $filter, previousSpots, previousDests) {
    /* PRIVATE : DATA */
    //Declare all private variables here
    var authConstants = new AuthConstant();
    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/

    var vm = this;
    var sessionData = loginService.getSessionData();
    var destsPopupShown = false;
    vm.destinationSortParams = {};

    var _DynamicDestinations = function _DynamicDestinations() {//this.reloadPages_();
    }; // Store the search params in memory as we go


    var SearchParams = {};

    _DynamicDestinations.prototype.getItemAtIndex = function (index, lazy) {
      var pageNumber = Math.floor(index / this.PAGE_SIZE);
      var page = this.loadedPages[pageNumber];
      lazy = lazy === undefined ? false : lazy;

      if (page) {
        return page[index % this.PAGE_SIZE];
      } else if (page !== null) {
        if (!lazy) {
          this.fetchPage_(pageNumber);
        }
      }
    };

    _DynamicDestinations.prototype.getLength = function () {
      return this.numItems;
    };

    _DynamicDestinations.prototype.setItemAtIndex = function (index, item) {
      var pageNumber = Math.floor(index / this.PAGE_SIZE);
      var page = this.loadedPages[pageNumber]; // Only update the screen if the spot is currently loaded

      if (page) {
        page[index % this.PAGE_SIZE] = item;
      }
    };

    _DynamicDestinations.prototype.fetchPage_ = function (pageNumber) {
      var self = this;
      vm.searching = true; // Set the page to null so we know it is already being fetched.

      self.loadedPages[pageNumber] = null;
      var pageOffset = pageNumber * self.PAGE_SIZE;
      var sortType = 'order';
      $timeout(function () {
        // since we have to add more criteria, shallow copy this into another object
        SearchParams = Object.assign({}, vm.destinationSearchParams); // Filter out blank values, since we don't want to search by those

        for (var prop in SearchParams) {
          if (SearchParams.hasOwnProperty(prop)) {
            SearchParams[prop] = SearchParams[prop] === '' || SearchParams[prop] === null ? undefined : SearchParams[prop];
          }
        }

        SearchParams.offset = pageOffset;
        SearchParams.limit = self.PAGE_SIZE;
        SearchParams.sortType = sortType; // limit to non-archived destinations

        SearchParams.archived = false; // limit to active destinations

        SearchParams.active = true; // limit to destinations where the receiver account is enabled

        SearchParams.enabledFlag = true; // filter out lite-only destinations

        SearchParams.excludeLite = !sessionData.account.showExpressDestinations; // Filter out VAST destinations if this provider isn't setup for them

        SearchParams.hideVast = !sessionData.account.allowVast; // check account settings for test destinations

        if (sessionData.account && sessionData.account.preProductionFlag && sessionData.account.showOnlyTestDestinationsFlag) {
          SearchParams.preProductionFlag = true;
        }

        switch (vm.orderType) {
          case 'PROMO':
            SearchParams.distributionType = 'PROMO';
            break;

          case 'TV':
            SearchParams.distributionType = 'TV';
            break;

          case 'RADIO':
            SearchParams.distributionType = 'RADIO';
            break;

          case 'DIGITAL':
            SearchParams.distributionType = 'DIGITAL';
            break;
        }

        vm.destinationSortParams.field = vm.destinationSortParams.field ? vm.destinationSortParams.field : 'date_created';
        vm.destinationSortParams.direction = vm.destinationSortParams.direction ? vm.destinationSortParams.direction : 'DESC';
        SearchParams.sortField = vm.destinationSortParams.field;
        SearchParams.sortDirection = vm.destinationSortParams.direction; // need to set this or else sorting won't work correctly

        SearchParams.sortType = 'destination';
        var multiParam = [];

        if (SearchParams.callLetter !== undefined) {
          // if they only entered one, then do a "LIKE" search via the alias param.  If multiple, then format it correctly and send via multiCallLetter param.
          var parsedInput = SearchParams.callLetter.trim().split(/\n+|,+|;+/);

          if (parsedInput instanceof Array && parsedInput.length > 1) {
            //remove any that ended up empty due to extra characters, and trim
            for (var i = 0; i < parsedInput.length; i++) {
              if (parsedInput[i].trim().length > 0) {
                multiParam.push(parsedInput[i].trim());
              }
            }

            SearchParams.multiCallLetter = multiParam.join();
            SearchParams.limit = null;
            SearchParams.offset = null;
          } else {
            SearchParams.alias = SearchParams.callLetter;
          }
        }

        if (SearchParams.destinationListId) {
          delete SearchParams.distributionType;
        }

        var idSearchParams = Object.assign({}, SearchParams);
        delete idSearchParams.offset;
        delete idSearchParams.limit;
        idSearchParams.idOnly = true;
        $q.all([DestinationResourceFactory.getAll(SearchParams, {}).$promise, DestinationResourceFactory.getAllIds(idSearchParams, {}).$promise]).then(function success(results) {
          // Arrays to house the invalid destinations and the valid destinations
          var invalidDistTypeArray = []; // Filter results if using a destination list

          if (SearchParams.destinationListId) {
            results[0].data.rows = results[0].data.rows.filter(function (dest) {
              // If this destination is not a valid type:
              // - note it in the array
              // - set the flag that says there are invalid destinations
              // - subtract that destination from the total count
              if (dest.DestinationType.distributionType !== vm.orderType) {
                invalidDistTypeArray.push(dest);
                dest.invalidType = true;
                results[0].data.count--;
              } // Return true or false for the filter


              return dest.DestinationType.distributionType === vm.orderType;
            });
          } // Set searching value to show the loading bar/veil


          vm.searching = false; // If using multiCallLetter param, then we need to check in the filtered results

          self.PAGE_SIZE = SearchParams.multiCallLetter ? results[0].data.rows.length : self.PAGE_SIZE; // Remove any duplicate call letters from the array

          var missingArray = multiParam;
          missingArray = missingArray.filter(function (item, pos) {
            return missingArray.indexOf(item) == pos; // jshint ignore:line
          }); // deal with the ID-only results

          var filteredResults = []; // eslint-disable-next-line

          results[1].data.map(function (data, ind, list) {
            // IF there are multiple call letters entered
            if (multiParam.length > 0) {
              // Loop through all of the call letters
              for (var _i = 0; _i < multiParam.length; _i++) {
                // Only perform the following logic if the destination has a call letter we're looking for, or has the right distribution type
                if ((data.alias.toUpperCase() === multiParam[_i].toUpperCase() || data.aka && data.aka.toUpperCase().indexOf('|' + multiParam[_i].toUpperCase() + '|') > -1) && data.distributionType === vm.orderType) {
                  // If we find a call letter in the results, remove it from the "missing" array
                  missingArray.splice(missingArray.indexOf(multiParam[_i]), 1); // If we are not already tracking this destination, add it to the list

                  if (!vm.destinationIds.includes(data.id)) {
                    vm.destinationIds.push(data.id);
                  }
                }
              }
            } else if (data.distributionType === vm.orderType) {
              // If there were no call letters entered, add all the destination ids
              vm.destinationIds.push(data.id);
            }
          });
          self.loadedPages[pageNumber] = filteredResults.length > 0 ? filteredResults : results[0].data.rows;
          self.numItems = filteredResults.length > 0 ? filteredResults.length : results[0].data.count;

          if (results[0].data.exactMatch && results[0].data.exactMatch.id) {
            if (vm.destinationIds.indexOf(results[0].data.exactMatch.id) !== 0) {
              for (var id = 1; id < vm.destinationIds.length; id++) {
                if (vm.destinationIds[id] === results[0].data.exactMatch.id) {
                  vm.destinationIds.splice(id, 1);
                }
              }

              vm.destinationIds.unshift(results[0].data.exactMatch.id);
            } // Check to make sure the very first item is the exact match, if not, make it so number one


            if (self.loadedPages[0][0].id !== results[0].data.exactMatch.id) {
              self.loadedPages[0].unshift(results[0].data.exactMatch);
            } // Then go through the results and make sure any duplicate rows get squashed


            for (var page = 0; page < Object.keys(self.loadedPages).length; page++) {
              for (var row = 0; row < Object.keys(self.loadedPages[page]).length; row++) {
                if (self.loadedPages[page][row].id === self.loadedPages[0][0].id) {
                  if (page === 0 && row === 0) {
                    // We don't want to squash the one we just inserted
                    continue;
                  }

                  self.loadedPages[page].splice(row, 1);
                }
              }
            }
          }

          if (invalidDistTypeArray.length > 0 && SearchParams.destinationListId) {
            var conflictMessage = 'This order is a ' + vm.orderType + ' order, but the following destinations are not.<br />' + 'These mismatched destinations will not be added along with the<br/>rest of the ' + vm.orderType + ' destinations in this list.<br /><br /><ul>';

            for (var _i2 = 0; _i2 < invalidDistTypeArray.length; _i2++) {
              conflictMessage += '<li>' + invalidDistTypeArray[_i2].alias + ' is type ' + invalidDistTypeArray[_i2].DestinationType.distributionType + '</li>';
            }

            conflictMessage += '</ul><br />';
            var alert = $mdDialog.alert().multiple(true).title('Order Type Mismatch').htmlContent(conflictMessage).ariaLabel(conflictMessage).ok('OK');
            $mdDialog.show(alert);
          } // tell user if destinations in multi-call-letter search weren't found (COM-11907)


          if (missingArray.length > 0 && !destsPopupShown) {
            destsPopupShown = true;

            var _conflictMessage = 'The following destinations were not found.  They either do not exist, do not match ' + 'the order type,<br />or were excluded by other selected search filters.<br /><br /><ul>';

            for (var _i3 = 0; _i3 < missingArray.length; _i3++) {
              _conflictMessage += '<li>' + missingArray[_i3] + '</li>';
            }

            _conflictMessage += '</ul><br />';

            var _alert = $mdDialog.alert().multiple(true).title('Warning').htmlContent(_conflictMessage).ariaLabel(_conflictMessage).ok('OK');

            $mdDialog.show(_alert);
          }

          vm.destinationCount = results[0].data.count;
          vm.searching = false; // load the destination statuses

          for (var _i4 = 0; _i4 < self.loadedPages[pageNumber].length; _i4++) {
            _getDestinationStatus(self.loadedPages[pageNumber][_i4].distributionType, self.loadedPages[pageNumber][_i4].ReceiverAccount.id, self.loadedPages[pageNumber][_i4].id);
          } // Select everything, if autoselect was enabled via code


          if (vm.autoselect === true) {
            vm.selectAllDestinations();
            vm.autoselect = false;
          }
        }, function failure(error) {
          NotificationService.showNotificationToast('Destination Search failed', error.data);
          vm.destinationIds = [];
          vm.destinationCount = 0;
          self.loadedPages[pageNumber] = [];
          vm.searching = false;
        });
      });
    };

    _DynamicDestinations.prototype.reloadPages_ = function () {
      /**
       * @type {!Object<?Array>} Data pages, keyed by page number (0-index).
       */
      this.loadedPages = {};
      /** @type {number} Total number of items. */

      this.numItems = 0;
      /** @type {number} Number of items to fetch per request. */

      this.PAGE_SIZE = 50;
      this.getItemAtIndex(0);
    };
    /* BINDABLE : DATA */


    vm.dynamicDestinations = new _DynamicDestinations();
    vm.spotStatuses = [];
    vm.spots = [];
    vm.spotThumbs = {};
    vm.spotSearchParams = {};
    vm.spotSortParams = {};
    vm.spotCount = 0;
    vm.selectedSpots = [];
    vm.lastSpotSelection = -1;
    vm.destinationTypes = [];
    vm.markets = [];
    vm.destinationLists = [];
    vm.destinationIds = [];
    vm.destinationStatuses = {};
    vm.destinationSearchParams = {};
    vm.destinationCount = 0;
    vm.selectedDestinations = [];
    vm.selectedTraffic = [];
    vm.selectedTabIndex = selectedTabIndex;
    vm.group = {
      OrderGroupTraffic: [],
      OGSpots: [],
      OGDestinations: []
    };
    vm.orderType = orderType;
    vm.allOrderGroups = angular.copy(allGroups);
    vm.originalGroup = angular.copy(group);
    vm.searching = false;
    vm.showMore = false;
    vm.moreButtonText = 'Advanced Search';
    vm.durations = sessionData.durations.filter(function (cur) {
      return loginService.isAdmin() || cur <= 300;
    });
    vm.enableAnimation = false;
    vm.trafficToUpload = [];
    vm.fileErrors = [];
    vm.filteredDuration = null;
    vm.filesToUpload = [];
    vm.loadingThumbs = [];
    vm.iscisToAdd = [];
    vm.showPreviousDests = false;
    vm.showPreviousSpots = false;
    vm.previouslySelected = {
      spots: [],
      destinations: []
    };
    vm.itemsRemoved = {
      id: group.id,
      spots: [],
      destinations: []
    };
    vm.destinationOrder = 'sequence';
    vm.spotOrder = 'sequence';
    vm.uploader = {};
    vm.isFranchise = sessionData.franchiseCustomer;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    vm.saveAndAddToAll = saveAndAddToAll;
    vm.resetSpotSearch = resetSpotSearch;
    vm.searchSpots = searchSpots;
    vm.selectSpot = selectSpot;
    vm.selectAllSpots = selectAllSpots;
    vm.sortSpots = sortSpots;
    vm.resetDestinationSearch = resetDestinationSearch;
    vm.searchDestinations = searchDestinations;
    vm.selectDestination = selectDestination;
    vm.selectAllDestinations = selectAllDestinations;
    vm.sortDestinations = sortDestinations;
    vm.removeTraffic = removeTraffic;
    vm.toggleMore = toggleMore;
    vm.loadAdvertisers = loadAdvertisers;
    vm.loadAgencies = loadAgencies;
    vm.loadBrands = loadBrands;
    vm.loadList = loadList;
    vm.clearList = clearList;
    vm.isSearchEmpty = isSearchEmpty;
    vm.deleteParam = deleteParam;
    vm.formatDuration = formatDuration;
    vm.filterFormats = filterFormats;
    vm.formIsClean = formIsClean;
    vm.validateName = validateName;
    vm.checkBasicSearchFields = checkBasicSearchFields;
    vm.removePreviouslySelected = removePreviouslySelected;
    vm.previousDestOrder = previousDestOrder;
    vm.previousSpotOrder = previousSpotOrder;
    vm.selectedAgency = selectedAgency;
    vm.selectedAdvertiser = selectedAdvertiser;
    vm.selectedBrand = selectedBrand;
    /* EVENTS */
    //Register any event listeners

    $scope.$watch(function () {
      return vm.filesToUpload;
    }, function (newVal, oldVal) {
      if (newVal.length === 0) {
        return;
      }

      angular.forEach(vm.filesToUpload, function (cur) {
        vm.trafficToUpload.push(cur);
      });
      vm.filesToUpload = [];
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel({
        itemsRemoved: vm.itemsRemoved
      });
    }

    function save() {
      _validateAndSave([parseInt(vm.originalGroup.id, 10)]);
    }

    function saveAndAddToAll() {
      if (parseInt(vm.originalGroup.id, 10) === -1) {
        vm.allOrderGroups.push({
          id: -1
        });
      }

      _validateAndSave(vm.allOrderGroups.map(function (cur) {
        return parseInt(cur.id, 10);
      }));
    }

    function resetSpotSearch() {
      vm.spotSearchParams = {};
      vm.searchSpots();
    }

    function checkBasicSearchFields() {
      return vm.spotSearchParams.isci && vm.spotSearchParams.isci !== undefined || vm.spotSearchParams.title && vm.spotSearchParams.title !== undefined || vm.spotSearchParams.SpotsStatus && vm.spotSearchParams.SpotsStatus !== undefined || vm.spotSearchParams.agencyId && vm.spotSearchParams.agencyId !== undefined || vm.spotSearchParams.advertiserId && vm.spotSearchParams.advertiserId !== undefined || vm.spotSearchParams.brandId && vm.spotSearchParams.brandId !== undefined || vm.spotSearchParams.duration && vm.spotSearchParams.duration !== undefined || vm.spotSearchParams.format && vm.spotSearchParams.format !== undefined;
    }

    function searchSpots() {
      vm.spots = [];
      vm.spotCount = 0;
      vm.lastSpotSelection = -1;
      vm.selectedSpots = [];
      vm.searching = true; // filter out blank params

      for (var prop in vm.spotSearchParams) {
        if (vm.spotSearchParams.hasOwnProperty(prop)) {
          vm.spotSearchParams[prop] = vm.spotSearchParams[prop] === '' || vm.spotSearchParams[prop] === null ? undefined : vm.spotSearchParams[prop];
        }
      }

      if (!checkBasicSearchFields()) {
        vm.spotSearchParams.dateUpdated = 'gte:' + moment(new Date()).subtract(7, 'days').hour(0).minute(0).second(0).format('MM-DD-YYYY h:mm:ss a');
      }

      switch (vm.orderType) {
        case 'PROMO':
          // promo flag
          vm.spotSearchParams.promo = true; //vm.spotSearchParams.mediaType = 'VIDEO';

          if (!vm.spotSearchParams.validTo) {
            vm.spotSearchParams.runEndDate = 'gte:' + moment().hour(0).minute(0).second(0).format('MM-DD-YYYY h:mm:ss a');
            vm.spotSearchParams.runStartDate = 'lte:' + moment().hour(0).minute(0).second(0).format('MM-DD-YYYY h:mm:ss a');
          }

          break;

        case 'TV':
          vm.spotSearchParams.promo = false; //vm.spotSearchParams.mediaType = 'VIDEO';

          break;

        case 'RADIO':
          //vm.spotSearchParams.mediaType = 'AUDIO';
          break;

        case 'DIGITAL':
          vm.spotSearchParams.allowComposite = true;
          break;
      }

      vm.spotSortParams.field = vm.spotSortParams.field ? vm.spotSortParams.field : 'date_created';
      vm.spotSortParams.direction = vm.spotSortParams.direction ? vm.spotSortParams.direction : 'DESC';

      if (vm.spotSearchParams.isci) {
        vm.spotSearchParams.isci = vm.spotSearchParams.isci.toUpperCase();
      }

      vm.spotSearchParams.sortField = vm.spotSortParams.field;
      vm.spotSearchParams.sortDirection = vm.spotSortParams.direction; //Adding orderType to do the search instead of mediaType as when an radio order is created mediaType is set to default to video from CAD as media comes much later.

      vm.spotSearchParams.orderType = vm.orderType; // COM-11675 - don't apply the default date restriction when we are searching from here

      vm.spotSearchParams.ignoreDateRestriction = true; // COM-11612 - this is a hack to get around the fact that title is often entered with a colon in it.
      // The colon messes up the Node parsing of the field, so pass this one like admin does

      var tempParams = angular.copy(vm.spotSearchParams); //COM-12102 - Have to copy/format the ISCIs properly in order to search for/create the right ones,
      // as well as retain the current search criteria

      if (vm.spotSearchParams.isci) {
        tempParams.isci = _formatIsci(tempParams.isci);

        if (tempParams.isci instanceof Array && tempParams.isci.length > 1) {
          tempParams.isci = tempParams.isci.join('\n');
        } else {
          tempParams.isci = tempParams.isci[0];
        }
      }

      if (vm.spotSearchParams.title) {
        tempParams.title = 'like:' + vm.spotSearchParams.title;
      }

      if (vm.spotSearchParams.SpotsStatus) {
        if (vm.spotSearchParams.SpotsStatus !== 'RESLATE') {
          tempParams.SpotsStatus = vm.spotSearchParams.SpotsStatus;
          delete tempParams.reslateFlag;
        } else {
          tempParams.reslateFlag = true;
          tempParams.SpotsStatus = 'UNSENT';
        }
      }

      for (var _i5 = 0, _Object$entries = Object.entries(tempParams); _i5 < _Object$entries.length; _i5++) {
        var _Object$entries$_i = _slicedToArray(_Object$entries[_i5], 2),
            key = _Object$entries$_i[0],
            value = _Object$entries$_i[1];

        if (value === undefined || value === null || value === '') {
          delete tempParams[key];
        }
      }

      SpotResourceFactory.getProviderSpots(tempParams).subscribe(function (spots) {
        vm.spots = spots.rows;
        vm.spotCount = spots.count;
        vm.spots = vm.spots.map(function (data, ind, list) {
          if (data.ThumbnailAsset && data.ThumbnailAsset.AssetContent && data.ThumbnailAsset.AssetContent.contentUuid) {
            // We only ever retrieve one thumbnail, so 0 is okay here
            _getThumbnail(data.isci, data.ThumbnailAsset.AssetContent.contentUuid);
          }

          return data;
        });
        vm.searching = false;

        if (vm.spotSearchParams.isci) {
          _checkUnfoundIscis();

          if (vm.iscisToAdd.length > 0) {
            _quickAddSpot(vm.iscisToAdd);
          }
        }

        delete vm.spotSearchParams.promo; //delete vm.spotSearchParams.mediaType;

        delete vm.spotSearchParams.sortField;
        delete vm.spotSearchParams.sortDirection;
      }, function (error) {
        NotificationService.showNotificationToast('Spot Search failed', error);
        vm.spots = [];
        vm.spotCount = 0;
        vm.searching = false;
      });

      if (vm.spotSearchParams.dateUpdated) {
        vm.spotSearchParams.dateUpdated = null;
      }
    }

    function selectSpot(index, event) {
      if (vm.selectedSpots.indexOf(index) >= 0) {
        vm.selectedSpots.splice(vm.selectedSpots.indexOf(index), 1);
        vm.lastSpotSelection = index;
      } else {
        if (event.shiftKey && vm.lastSpotSelection > -1) {
          // Handle multi-select
          var toIndex = Math.max(index, vm.lastSpotSelection);
          var fromIndex = Math.min(index, vm.lastSpotSelection);

          for (var i = fromIndex; i <= toIndex; i++) {
            if (vm.selectedSpots.indexOf(i) < 0) {
              vm.selectedSpots.push(i);
            }
          }
        } else if (vm.selectedSpots.indexOf(index) < 0) {
          vm.selectedSpots.push(index);
        }

        vm.lastSpotSelection = index;
      }
    }

    function selectAllSpots() {
      vm.lastSpotSelection = -1;

      if (vm.selectedSpots.length === vm.spots.length) {
        vm.selectedSpots = [];
      } else {
        vm.selectedSpots = [];

        for (var i = 0; i < vm.spots.length; i++) {
          vm.selectedSpots.push(i);
        }
      }
    }

    function sortSpots(field) {
      if (vm.spotSortParams.field === field) {
        vm.spotSortParams.direction = vm.spotSortParams.direction === 'ASC' ? 'DESC' : 'ASC';
      } else {
        vm.spotSortParams.field = field;
        vm.spotSortParams.direction = 'ASC';
      }

      vm.searchSpots();
    }

    function resetDestinationSearch() {
      vm.destinationSearchParams = {};
    }

    function searchDestinations() {
      if (!isSearchEmpty(vm.destinationSearchParams)) {
        destsPopupShown = false;
        vm.destinationIds = [];
        vm.destinationCount = 0;
        vm.lastDestinationSelection = -1;
        vm.selectedDestinations = [];

        if (vm.destinationSearchParams.destinationListId) {
          vm.autoselect = true;
        }

        vm.dynamicDestinations.reloadPages_();
      }
    }

    function selectDestination(index, event) {
      if (vm.selectedDestinations.indexOf(index) >= 0) {
        vm.selectedDestinations.splice(vm.selectedDestinations.indexOf(index), 1);
        vm.lastDestinationSelection = index;
      } else {
        if (event.shiftKey && vm.lastDestinationSelection > -1) {
          // Handle multi-select
          var toIndex = Math.max(index, vm.lastDestinationSelection);
          var fromIndex = Math.min(index, vm.lastDestinationSelection);

          for (var i = fromIndex; i <= toIndex; i++) {
            if (vm.selectedDestinations.indexOf(i) < 0) {
              vm.selectedDestinations.push(i);
            }
          }
        } else if (vm.selectedDestinations.indexOf(index) < 0) {
          vm.selectedDestinations.push(index);
        }

        vm.lastDestinationSelection = index;
      }
    }

    function selectAllDestinations() {
      vm.lastDestinationSelection = -1;

      if (vm.selectedDestinations.length === vm.dynamicDestinations.numItems) {
        vm.selectedDestinations = [];
      } else {
        vm.selectedDestinations = [];

        for (var i = 0; i < vm.dynamicDestinations.numItems; i++) {
          vm.selectedDestinations.push(i);
        }
      }
    }

    function sortDestinations(field) {
      if (vm.destinationSortParams.field === field) {
        vm.destinationSortParams.direction = vm.destinationSortParams.direction === 'ASC' ? 'DESC' : 'ASC';
      } else {
        vm.destinationSortParams.field = field;
        vm.destinationSortParams.direction = 'ASC';
      }

      searchDestinations();
    }

    function removeTraffic(index) {
      vm.trafficToUpload.splice(index, 1);
    }

    function toggleMore() {
      vm.enableAnimation = true;
      vm.showMore = !vm.showMore;
      vm.moreButtonText = vm.showMore ? 'Close' : 'Advanced Search';
    }

    function loadAdvertisers(searchName) {
      return new Promise(function (resolve, reject) {
        AdvertiserResourceFactory.getAll({
          agencyId: vm.spotSearchParams.agencyId ? vm.spotSearchParams.agencyId : null,
          name: searchName,
          active: true,
          limit: 15
        }).subscribe(function (advertisers) {
          advertisers.sort(function (a, b) {
            return a.name - b.name;
          });
          resolve(advertisers);
        }, function () {
          resolve([]);
        });
      });
    }

    function selectedAdvertiser(advertiser) {
      vm.deleteParam('advertiserId');

      if (advertiser && advertiser.id != vm.spotSearchParams.advertiserId || advertiser == null) {
        vm.searchBrand = '';
        vm.spotSearchParams.brandId = null;
      }

      vm.spotSearchParams.advertiserId = advertiser ? advertiser.id : null;

      if (vm.searchAdvertiser == '') {
        vm.spotSearchParams.advertiserId = null;
        vm.searchBrand = '';
        vm.spotSearchParams.brandId = null;
      }

      vm.newAdvertiser = true;
    }

    function loadAgencies(searchName) {
      return new Promise(function (resolve, reject) {
        AgencyResourceFactory.getAll({
          name: searchName,
          activeOnly: true,
          limit: 15
        }).subscribe(function (agencies) {
          resolve(agencies.rows);
        }, function () {
          resolve([]);
        });
      });
    }

    function selectedAgency(agency) {
      vm.deleteParam('agencyId');

      if (agency && agency.id != vm.spotSearchParams.agencyId || agency == null) {
        vm.searchAdvertiser = '';
        vm.spotSearchParams.advertiserId = null;
        vm.searchBrand = '';
        vm.spotSearchParams.brandId = null;
      }

      vm.spotSearchParams.agencyId = agency ? agency.id : null;

      if (vm.searchAgencies == '') {
        vm.spotSearchParams.agencyId = null;
        vm.searchAdvertiser = '';
        vm.spotSearchParams.advertiserId = null;
        vm.searchBrand = '';
        vm.spotSearchParams.brandId = null;
      }

      vm.newAgency = true;
    }

    function loadBrands(searchName) {
      return new Promise(function (resolve, reject) {
        BrandsResourceFactory.getAll({
          advertiserId: vm.spotSearchParams.advertiserId ? vm.spotSearchParams.advertiserId : null,
          name: searchName,
          limit: 15
        }).subscribe(function (brands) {
          resolve(brands);
        }, function () {
          resolve([]);
        });
      });
    }

    function selectedBrand(brand) {
      vm.deleteParam('brandId');
      vm.spotSearchParams.brandId = brand ? brand.id : null;

      if (vm.searchBrand == '') {
        vm.spotSearchParams.brandId = null;
      }

      vm.newBrand = true;
    }

    function loadList(updateParam) {
      vm.deleteParam(updateParam); // if they choose a list, we need to clear out any other criteria entered.

      if (vm.destinationSearchParams.callLetter) {
        delete vm.destinationSearchParams.callLetter;
      }

      if (vm.destinationSearchParams.destinationName) {
        delete vm.destinationSearchParams.destinationName;
      }

      if (vm.destinationSearchParams.destinationTypeId) {
        delete vm.destinationSearchParams.destinationTypeId;
      }

      if (vm.destinationSearchParams.marketId) {
        delete vm.destinationSearchParams.marketId;
      }

      for (var i = 0; i < vm.destinationLists.length; i++) {
        if (vm.destinationLists[i].index === vm.destinationSearchParams.destinationListIndex) {
          vm.destinationSearchParams.destinationListId = vm.destinationLists[i].id;
          vm.destinationSearchParams.destinationListType = vm.destinationLists[i].type;
          break;
        }
      }
    }

    function clearList() {
      vm.autoselect = false;

      if (vm.destinationSearchParams.destinationListIndex) {
        delete vm.destinationSearchParams.destinationListIndex;
        delete vm.destinationSearchParams.destinationListId;
        delete vm.destinationSearchParams.destinationListType;
      }
    }

    function deleteParam(param) {
      if (vm.spotSearchParams[param] === '' || vm.spotSearchParams[param] === null) {
        delete vm.spotSearchParams[param];
      } else if (vm.destinationSearchParams[param] === '' || vm.destinationSearchParams[param] === null) {
        delete vm.destinationSearchParams[param];
      }

      if (param === 'destinationListId') {
        delete vm.destinationSearchParams.destinationListType;

        if (vm.destinationSearchParams.destinationListIndex === '') {
          delete vm.destinationSearchParams.destinationListId;
          delete vm.destinationSearchParams.destinationListIndex;
        }
      }
    }

    function isSearchEmpty(params) {
      return angular.equals({}, params);
    }

    function formatDuration() {
      vm.filteredDuration = $filter('timeDuration')(vm.spotSearchParams.duration);
    }

    function filterFormats() {
      if (vm.orderType === 'TV') {
        for (var i = 0; i < vm.formats.length; i++) {
          if (vm.formats[i].value === 'RADIO') {
            vm.formats.splice(i, 1);
          }
        }
      } else if (vm.orderType === 'RADIO') {
        for (var _i6 = 0; _i6 < vm.formats.length; _i6++) {
          if (vm.formats[_i6].value === 'HD' || vm.formats[_i6].value === 'SD') {
            vm.formats.splice(_i6, 1);
          }
        }
      }
    }

    function formIsClean() {
      // Check if the form has any real changes, and not just search fields
      var nameClean = vm.originalGroup.name === group.name;
      var noSpotsSelected = !vm.selectedSpots || vm.selectedSpots.length === 0;
      var noDestinationsSelected = !vm.selectedDestinations || vm.selectedDestinations.length === 0;
      var noTrafficSelected = !vm.trafficToUpload || vm.trafficToUpload.length === 0;
      return nameClean && noSpotsSelected && noDestinationsSelected && noTrafficSelected;
    }

    function validateName() {
      for (var i = 0; i < allGroups.length; i++) {
        if (allGroups[i].name === $scope.groupDetailForm.nameInput.$viewValue && allGroups[i].id !== group.id) {
          $scope.groupDetailForm.$valid = false;
          $scope.groupDetailForm.nameInput.$valid = false;
          return true;
        }
      }

      return false;
    }

    function removePreviouslySelected(item, category, itemIndex) {
      switch (category) {
        case 'destinations':
          OrderResourceFactory.removeOrderGroupItem({
            id: group.orderId,
            groupId: group.id,
            OGDestinations: [item.id]
          }, function success() {
            var index = vm.previouslySelected[category].indexOf(item);
            vm.previouslySelected[category].splice(index, 1);
            vm.itemsRemoved[category].push(item);
          }, function failure(reason) {
            NotificationService.showNotificationToast('Failed to remove destination from order group', reason.data);
          });
          break;

        case 'spots':
          OrderResourceFactory.removeOrderGroupItem({
            id: group.orderId,
            groupId: group.id,
            OGSpots: [item.id]
          }, function success() {
            var index = vm.previouslySelected[category].indexOf(item);
            vm.previouslySelected[category].splice(index, 1);
            vm.itemsRemoved[category].push(item);
          }, function failure(reason) {
            NotificationService.showNotificationToast('Failed to remove spot from order group', reason.data);
          });
          break;
      }
    }

    function previousDestOrder(order) {
      if (!vm.destinationOrder.includes('-') && vm.destinationOrder.includes(order)) {
        vm.destinationOrder = '-' + order;
      } else {
        vm.destinationOrder = order;
      }
    }

    function previousSpotOrder(order) {
      if (!vm.spotOrder.includes('-') && vm.spotOrder.includes(order)) {
        vm.spotOrder = '-' + order;
      } else {
        vm.spotOrder = order;
      }
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    var _getThumbnail = function _getThumbnail(isci, uuid) {
      vm.loadingThumbs[isci] = true; // let's make sure we don't already have it, like if they are just sorting

      if (!vm.spotThumbs.hasOwnProperty(isci)) {
        AssetResourceFactory.getImageAsset({
          uuid: uuid
        }, {}, function success(asset) {
          vm.loadingThumbs[isci] = false;
          var blob = new Blob([asset.data], {
            type: asset.config['Content-Type']
          });
          $timeout(function () {
            vm.spotThumbs[isci] = URL.createObjectURL(blob);
          });
        }, function failure(err) {
          vm.loadingThumbs[isci] = false; // Unable to retrieve the image asset for the thumbnail, we don't care
        });
      }
    };

    function _validateAndSave(arrayOfGroupIds) {
      vm.saving = true;
      vm.uploader = new Flow({
        target: EndPointService.uploadTrafficAssetEndpoint,
        headers: function headers(flowFile, flowChunk, isTest) {
          var accountHash = JSON.parse($window.localStorage[authConstants.session.ACCOUNT_HASH]);
          var windowSessionData = JSON.parse($window.sessionStorage[authConstants.session.SESSION_DATA]);
          return {
            Authorization: 'Bearer ' + accountHash[windowSessionData.accountId]
          };
        },
        testMethod: false,
        fileParameterName: 'Filedata',
        uploadMethod: 'POST',
        withCredentials: true,
        allowDuplicateUploads: true,
        // 5Mb chunks, should force everything to go at once, since it's limited to that max size
        chunkSize: 5 * 1024 * 1024,
        simultaneousUploads: 10,
        query: {
          ignoreLoadingBar: true,
          waitForAutoConversions: false
        },
        testChunks: false
      });

      for (var traffic in vm.trafficToUpload) {
        if (!vm.trafficToUpload.hasOwnProperty(traffic)) {
          // Skip this traffic if it's some inherited property
          break;
        } // Add the spot file to the uploader


        vm.uploader.addFile(vm.trafficToUpload[traffic]);
      }

      var newAssetIds = [];
      vm.uploader.upload();
      vm.uploader.on('fileSuccess', function (file, message, chunk) {
        newAssetIds.push(message);
      });
      vm.uploader.on('complete', function () {
        // Build group object with the new things being added
        var groupToSave = _buildGroupUpdateObject(); // Build orderGroupTraffic. All new traffic is new, since we don't check for dupes on user files


        for (var id in newAssetIds) {
          if (!newAssetIds.hasOwnProperty(id)) {
            break;
          }

          groupToSave.OrderGroupTraffic.push({
            sequence: null,
            trafficAssetId: newAssetIds[id]
          });
        } // see if they have only added traffic so we can skip telling them about conflicts


        var trafficOnly = vm.trafficToUpload.length > 0 && vm.selectedDestinations.length === 0 && vm.selectedSpots.length === 0; // Only attempt to save if there is something to save

        if (groupToSave && !trafficOnly) {
          groupToSave.name = groupToSave.name.replace(/&/g, '&amp;'); // Do the checks for Order Item "goodness"

          OrderResourceFactory.validateSpots({
            id: vm.originalGroup.orderId
          }, {
            groupIds: arrayOfGroupIds,
            spots: groupToSave.OGSpots,
            destinations: groupToSave.OGDestinations
          }, function (response) {
            var formatConflictsPromise = $q.defer();
            var isciViolationPromise = $q.defer();

            if (orderType === 'RADIO') {
              formatConflictsPromise.resolve(true);
            }

            var sitRep = response.data;
            var conflictMessage = '';

            if (sitRep.hasSDSpots && sitRep.disallowSd) {
              if (sitRep.addingSpots) {
                conflictMessage += 'Attempting to add SD spots, but the following destinations do not accept SD content.<br /><ul>';

                for (var i = 0; i < sitRep.disallowSdDestinations.length; i++) {
                  conflictMessage += '<li>' + sitRep.disallowSdDestinations[i] + '</li>';
                }

                conflictMessage += '</ul><br />';
              } else {
                conflictMessage += 'Attempting to add destinations which cannot accept the following SD spots.<br /><ul>';

                for (var ii = 0; ii < sitRep.sdSpotIscis.length; ii++) {
                  conflictMessage += '<li>' + sitRep.sdSpotIscis[ii] + '</li>';
                }

                conflictMessage += '</ul><br />';
              }
            }

            if (sitRep.hasHDSpots && sitRep.disallowHd) {
              if (sitRep.addingSpots) {
                conflictMessage += 'Attempting to add HD spots, but the following destinations do not accept HD content.<br /><ul>';

                for (var j = 0; j < sitRep.disallowHdDestinations.length; j++) {
                  conflictMessage += '<li>' + sitRep.disallowHdDestinations[j] + '</li>';
                }

                conflictMessage += '</ul><br />';
              } else {
                conflictMessage += 'Attempting to add destinations which cannot accept the following HD spots.<br /><ul>';

                for (var jj = 0; jj < sitRep.hdSpotIscis.length; jj++) {
                  conflictMessage += '<li>' + sitRep.hdSpotIscis[jj] + '</li>';
                }

                conflictMessage += '</ul><br />';
              }
            }

            if (sitRep.hasNBSpots && sitRep.disallowNb) {
              if (sitRep.addingSpots) {
                conflictMessage += 'Attempting to add Non-Broadcast spots, but the following destinations do not accept Non-Broadcast content.<br /><ul>';

                for (var _j = 0; _j < sitRep.disallowNbDestinations.length; _j++) {
                  conflictMessage += '<li>' + sitRep.disallowNbDestinations[_j] + '</li>';
                }

                conflictMessage += '</ul><br />';
              } else {
                conflictMessage += 'Attempting to add destinations which cannot accept the following Non-Broadcast spots.<br /><ul>';

                for (var _jj = 0; _jj < sitRep.NbSpotIscis.length; _jj++) {
                  conflictMessage += '<li>' + sitRep.NbSpotIscis[_jj] + '</li>';
                }

                conflictMessage += '</ul><br />';
              }
            }

            if (conflictMessage !== '' && orderType !== 'RADIO') {
              conflictMessage += 'Do you wish to continue?<br />If you continue and submit this order without fixing these spots, the spots will be removed from the order.';
              var continueOrNot;
              var confirm = $mdDialog.confirm().multiple(true).title('Warning').htmlContent(conflictMessage).ariaLabel(conflictMessage).ok('Yes').cancel('No');
              continueOrNot = $mdDialog.show(confirm);
              continueOrNot.then(function () {
                formatConflictsPromise.resolve(true);
              }, function () {
                // User clicked "Cancel Submit"
                formatConflictsPromise.reject(false);
              });
            } else {
              formatConflictsPromise.resolve(true);
            }

            formatConflictsPromise.promise.then(function () {
              // ISCI Validation
              var validationMessage = '';

              if (sitRep.strictViolation && sitRep.sdStrictViolations.length > 0) {
                validationMessage += 'Attempting to add SD spots that have an "H" suffix on the ISCI/Ad-ID.<br /><ul>';

                for (var x = 0; x < sitRep.sdStrictViolations.length; x++) {
                  validationMessage += '<li>' + sitRep.sdStrictViolations[x] + '</li>';
                }

                validationMessage += '</ul><br />';
              }

              if (sitRep.strictViolation && sitRep.hdStrictViolations.length > 0) {
                validationMessage += 'Attempting to add HD spots that do not have an "H" suffix on the ISCI/Ad-ID.<br /><ul>';

                for (var y = 0; y < sitRep.hdStrictViolations.length; y++) {
                  validationMessage += '<li>' + sitRep.hdStrictViolations[y] + '</li>';
                }

                validationMessage += '</ul><br />';
              }

              if (validationMessage !== '') {
                validationMessage += 'Do you wish to continue?<br />If you continue and submit this order without fixing these spots, the spots will be removed from the order.';

                var _continueOrNot;

                var confirm = $mdDialog.confirm().multiple(true).title('Warning').htmlContent(validationMessage).ariaLabel(validationMessage).ok('Yes').cancel('No');
                _continueOrNot = $mdDialog.show(confirm);

                _continueOrNot.then(function () {
                  isciViolationPromise.resolve(true);
                }, function () {
                  // User clicked "Cancel Submit"
                  isciViolationPromise.reject(false);
                });
              } else {
                isciViolationPromise.resolve(true);
              }
            }, function rejection() {
              isciViolationPromise.reject(false);
              vm.saving = false;
            });
            isciViolationPromise.promise.then(function resolved() {
              _doSave(arrayOfGroupIds, groupToSave);
            }, function rejected() {
              // Well the user decided not to continue
              vm.saving = false;
            });
          }, function (err) {
            console.log(err);
          });
        } else if (trafficOnly) {
          _doSave(arrayOfGroupIds, groupToSave);
        } else {
          // Nothing new to save
          $mdDialog.hide({
            itemsRemoved: vm.itemsRemoved
          });
        }
      });
    }

    var _doSave = function _doSave(arrayOfGroupIds, groupToSave) {
      // Hang on to all the requests we make to update groups so we can track them and do them all at once
      var groupsUpdating = [];

      var _loop = function _loop(groupId) {
        // Customize groupToSave for each group we save to
        tempGroupToSave = angular.copy(groupToSave); // Append the groupId to each traffic document

        tempGroupToSave.OrderGroupTraffic = tempGroupToSave.OrderGroupTraffic.map(function (ogt) {
          ogt.orderGroupId = arrayOfGroupIds[groupId];
          return ogt;
        });

        if (arrayOfGroupIds[groupId] !== parseInt(vm.originalGroup.id, 10)) {
          // Don't want to mass update these attributes, that would be messy and bad
          delete tempGroupToSave.name;
          delete tempGroupToSave.sequence;
        } // Attempt to save the items to each order group selected (either one or all)


        groupsUpdating.push(OrderResourceFactory.saveOrderGroup({
          id: vm.originalGroup.orderId,
          groupId: arrayOfGroupIds[groupId]
        }, tempGroupToSave).$promise);
      };

      for (var groupId = 0; groupId < arrayOfGroupIds.length; groupId++) {
        var tempGroupToSave;

        _loop(groupId);
      } // Once we have all the update requests made and tracked, wait for them all to finish


      return $q.all(groupsUpdating).then(function success(response) {
        var updatedIds = response.map(function (res) {
          return parseInt(res.data, 10);
        });
        var saveMessage = '';

        if (response.length > 1) {
          saveMessage = 'Saved to all order groups successfully';
        } else {
          saveMessage = 'Order Group ' + (vm.originalGroup.name ? vm.originalGroup.name : "'no name'") + ' successfully saved';
        }

        vm.saving = false;
        NotificationService.showNotificationToast(saveMessage);
        $mdDialog.hide({
          groupIds: updatedIds,
          itemsRemoved: vm.itemsRemoved
        });
      }, function failure(err) {
        vm.saving = false;
        NotificationService.showNotificationToast('Order Group save for ' + (vm.originalGroup.name ? vm.originalGroup.name : "'no name'") + ' failed', err.data);
        console.log(err);
      });
    };

    var _getDestinationStatus = function _getDestinationStatus(distributionType, receiverId, destinationId) {
      DestinationResourceFactory.getStatus({
        id: destinationId,
        distributionType: distributionType,
        receiverId: receiverId
      }, function (status) {
        vm.destinationStatuses[destinationId] = status.data === null || status.data === undefined ? 'false' : status.data.toString();
      }, function failure(err) {
        console.log(err);
      });
    };

    var _buildGroupUpdateObject = function _buildGroupUpdateObject() {
      if (vm.originalGroup.name !== group.name || vm.selectedSpots.length > 0 || vm.selectedDestinations.length > 0 || vm.trafficToUpload.length > 0) {
        var groupItemsObject = {
          name: vm.originalGroup.name,
          OGSpots: [],
          OGDestinations: [],
          OrderGroupTraffic: [],
          sequence: group.sequence
        }; // Put the new spot IDs in the object

        if (vm.selectedSpots.length > 0) {
          for (var i = 0; i < vm.selectedSpots.length; i++) {
            groupItemsObject.OGSpots.push(vm.spots[vm.selectedSpots[i]].id);
          }
        } // Put the new destination IDs in the object


        if (vm.selectedDestinations.length > 0) {
          for (var k = 0; k < vm.selectedDestinations.length; k++) {
            groupItemsObject.OGDestinations.push(vm.destinationIds[vm.selectedDestinations[k]]);
          }
        }

        return groupItemsObject;
      } else {
        // tell calling function that nothing changed
        return false;
      }
    };

    function _quickAddSpot(notFoundSpots) {
      $mdDialog.show({
        controller: QuickAddSpotDialogController,
        controllerAs: 'vm',
        template: require('./dialogs/quickAddSpotDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: true,
        // For small screens only
        locals: {
          notFoundSpots: notFoundSpots
        }
      }).then(function success(spotsToCreate) {
        if (sessionData.isFilteredUser && spotsToCreate.length > 0) {
          _openMetadataDialog(spotsToCreate);
        } else {
          _createSelectedSpots(spotsToCreate);
        }
      }, function canceled(err) {//user closed the dialog
      });
      vm.iscisToAdd = [];
    }

    function _openMetadataDialog(selectedSpots) {
      $mdDialog.show({
        controller: metadataDialog.controller,
        template: metadataDialog.template,
        controllerAs: 'vm',
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        multiple: true,
        fullscreen: true,
        // For small screens only
        locals: {
          selectedSpots: selectedSpots
        }
      }).then(function success(completeSpots) {
        _createSelectedSpots(completeSpots);
      }, function failure(err) {//user closed the dialog
      });
    }

    function _createSelectedSpots(spotsToCreate) {
      var spotsCreating = [];

      var _loop2 = function _loop2(i) {
        var currSpot = spotsToCreate[i];
        currSpot.promo = vm.spotSearchParams.promo;

        if (_checkIfHdIsci(currSpot.isci)) {
          currSpot.format = 'HD';
          currSpot.hdFlag = true;
          currSpot.downConvertPreference = 'CENTERCUT';
        }

        if (vm.orderType === 'RADIO') {
          currSpot.format = 'RADIO'; //currSpot.mediaType = 'AUDIO';
        } else if (vm.orderType === 'PROMO') {
          currSpot.promo = true;
        }

        spotsCreating.push(SpotResourceFactory.create({}, currSpot).then(function () {
          NotificationService.showNotificationToast('Spot ' + currSpot.isci + ' successfully saved');
        }, function (err) {
          NotificationService.showNotificationToast('Creation for spot ' + currSpot.isci + ' failed', err);
        }));
      };

      for (var i = 0; i < spotsToCreate.length; i++) {
        _loop2(i);
      }

      if (spotsCreating.length > 0) {
        $q.all(spotsCreating).then(function success(response) {
          vm.searchSpots();
        });
      }
    }

    function _checkUnfoundIscis() {
      var iscisToCompare = _formatIsci(vm.spotSearchParams.isci);

      for (var i = 0; i < iscisToCompare.length; i++) {
        _compareIscis(iscisToCompare[i]);
      }
    }

    function _compareIscis(spotIsci) {
      var found = false;

      for (var j = 0; j < vm.spots.length; j++) {
        if (spotIsci === vm.spots[j].isci) {
          found = true;
        }
      }

      if (!found) {
        vm.iscisToAdd.push({
          isci: spotIsci
        });
      }
    }

    function _checkIfHdIsci(isci) {
      return /^[a-zA-Z0-9]+[hH]$/.test(isci);
    }

    function _formatIsci(isci) {
      var parsedIsci = isci.trim().split(/\n+|,+|;+/);

      if (parsedIsci instanceof Array && parsedIsci.length > 1) {
        var validIsciArray = []; //remove any that ended up empty due to extra characters, and trim

        for (var i = 0; i < parsedIsci.length; i++) {
          if (parsedIsci[i].trim().length > 0) {
            validIsciArray.push(parsedIsci[i].trim().replace(/\W/g, '').toUpperCase());
          }
        }

        return validIsciArray;
      } else {
        return [isci.replace(/\W/g, '')];
      }
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      vm.saving = false;

      for (var i = 0; i < previousSpots.length; i++) {
        vm.previouslySelected.spots.push(previousSpots[i]);
      }

      for (var j = 0; j < previousDests.length; j++) {
        vm.previouslySelected.destinations.push(previousDests[j]);
      }

      EnumService.getEnum('Spot', 'status').then(function (spotStatuses) {
        vm.spotStatuses = spotStatuses; // Need to handle reslate spots manually since this is not an actual 'status', but a flag

        vm.spotStatuses.push({
          value: 'RESLATE',
          label: 'Reslate Needed'
        });
      }, function () {
        vm.spotStatuses = [];
      });
      var distType;

      switch (vm.orderType) {
        case 'PROMO':
          distType = 'PROMO';
          break;

        case 'TV':
          distType = 'TV';
          break;

        case 'RADIO':
          distType = 'RADIO';
          break;

        case 'DIGITAL':
          distType = 'DIGITAL';
          break;
      }

      DestinationTypeResourceFactory.getAll({
        distributionType: distType
      }, function (types) {
        vm.destinationTypes = types.data;
      }, function () {
        vm.destinationTypes = [];
      }); // Modify this to retrieve System Destination Groups as well

      DestinationListResourceFactory.getAllWithSystem({
        providerAccountId: sessionData.accountId
      }, function (lists) {
        // Convert lists object from two arrays to one, keeping track of system-wide lists
        var accountLists = lists.data.destinationLists.map(function (cur, index, arr) {
          return {
            index: 'account' + cur.id,
            type: 'account',
            id: cur.id,
            name: cur.name
          };
        });
        var systemLists = lists.data.systemDestinationGroups.map(function (cur, index, arr) {
          return {
            index: 'system' + cur.id,
            type: 'system',
            id: cur.id,
            name: cur.name
          };
        });
        vm.destinationLists = [].concat(_toConsumableArray(accountLists), _toConsumableArray(systemLists));
      }, function () {
        vm.destinationLists = [];
      });
      MarketResourceFactory.getAll({}, function (markets) {
        vm.markets = markets.data;
      }, function () {
        vm.markets = [];
      });
      EnumService.getEnum('Spot', 'format').then(function (formats) {
        vm.formats = formats;
        vm.filterFormats();
      }, function () {
        vm.formats = [];
      });
      vm.searchSpots();
    }
  }]);
})();